import * as React from "react"
import { Link, graphql } from "gatsby"

// import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroBreadcrumb from "../components/HeroBreadcrumb"
import SideBar from "../components/SideBar"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  // return <Layout location={location} title={siteTitle}>
  // <h1>hi</h1></Layout>

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <HeroBreadcrumb pageTitle={post.frontmatter.title} />



        <div className="blog-single-area pt-120 pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="blog-single-wrapper">
                            <div className="blog-single-content">
                                <div className="blog-thumb-img">
                                    <img src="/assets/img/blog/single.jpg" alt="thumb" />
                                </div>
                                <div className="blog-info">
                                    {/*<div className="blog-meta">*/}
                                    {/*    /!*<div className="blog-meta-left">*!/*/}
                                    {/*    /!*    <ul>*!/*/}
                                    {/*    /!*        <li><i className="far fa-user"></i>Eleanor Walters</li>*!/*/}
                                    {/*    /!*        <li><i className="far fa-comments"></i>3.2k Comments</li>*!/*/}
                                    {/*    /!*        <li><i className="far fa-thumbs-up"></i>1.4k Like</li>*!/*/}
                                    {/*    /!*    </ul>*!/*/}
                                    {/*    /!*</div>*!/*/}
                                    {/*    /!*<div className="blog-meta-right">*!/*/}
                                    {/*    /!*    <a href="#" className="share-btn"><i className="far fa-share-alt"></i>Share</a>*!/*/}
                                    {/*    /!*</div>*!/*/}
                                    {/*</div>*/}
                                    <div className="blog-details">
                                        <h3 className="blog-details-title mb-20">{post.frontmatter.title}</h3>

{/* <p>Posted {post.frontmatter.date} </p> */}


                                        <section
                                        className="blog-details-post"
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />




<hr />
<nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={`${previous.fields.slug}`} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={`${next.fields.slug}`} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
      <hr />





                                        <hr />
                                        {/*<div className="blog-details-tags pb-20">*/}
                                        {/*    <h5>Tags : </h5>*/}
                                        {/*    <ul>*/}
                                        {/*        <li><a href="/blog/">Health</a></li>*/}
                                        {/*        <li><a href="/blog/">Fitness</a></li>*/}
                                        {/*        <li><a href="/blog/">Personal Training</a></li>*/}
                                        {/*    </ul>*/}
                                        {/*</div>*/}
                                    </div>
                                    {false && (
                                    <div className="blog-author">
                                        <div className="blog-author-img">
                                            <img src="/assets/img/blog/author.jpg" alt="" />

                                        </div>
                                        <div className="author-info">
                                            <h6>Author</h6>
                                            <h3 className="author-name">Roger D Duque</h3>
                                            {/* <p>It is a long established fact that a reader will be distracted by the
                                                readable content of a page when looking at its layout that it has a more
                                                less.</p> */}
                                            <div className="author-social">
                                                <a href="#"><i className="fab fa-facebook-f"></i></a>
                                                <a href="#"><i className="fab fa-twitter"></i></a>
                                                <a href="#"><i className="fab fa-instagram"></i></a>
                                                <a href="#"><i className="fab fa-whatsapp"></i></a>
                                                <a href="#"><i className="fab fa-youtube"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                </div>
                                {false && (<div className="blog-comments">
                                    <h3>Comments (20)</h3>
                                    <div className="blog-comments-wrapper">
                                        <div className="blog-comments-single">
                                            <img src="/assets/img/blog/com-1.jpg" alt="thumb" />
                                            <div className="blog-comments-content">
                                                <h5>Jesse Sinkler</h5>
                                                <span><i className="far fa-clock"></i> 20 August, 2021</span>
                                                <p>There are many variations of passages the majority have suffered in
                                                    some injected humour or randomised words which don't look even
                                                    slightly believable.</p>
                                                <a href="#"><i className="far fa-reply"></i> Reply</a>
                                            </div>
                                        </div>
                                        <div className="blog-comments-single blog-comments-reply">
                                            <img src="/assets/img/blog/com-2.jpg" alt="thumb" />
                                            <div className="blog-comments-content">
                                                <h5>Daniel Wellman</h5>
                                                <span><i className="far fa-clock"></i> 20 August, 2021</span>
                                                <p>There are many variations of passages the majority have suffered in
                                                    some injected humour or randomised words which don't look even
                                                    slightly believable.</p>
                                                <a href="#"><i className="far fa-reply"></i> Reply</a>
                                            </div>
                                        </div>
                                        <div className="blog-comments-single">
                                            <img src="/assets/img/blog/com-3.jpg" alt="thumb" />
                                            <div className="blog-comments-content">
                                                <h5>Kenneth Evans</h5>
                                                <span><i className="far fa-clock"></i> 20 August, 2021</span>
                                                <p>There are many variations of passages the majority have suffered in
                                                    some injected humour or randomised words which don't look even
                                                    slightly believable.</p>
                                                <a href="#"><i className="far fa-reply"></i> Reply</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blog-comments-form">
                                        <h3>Leave A Comment</h3>
                                        <form >
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control"
                                                            placeholder="Your Name*" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input type="email" className="form-control"
                                                            placeholder="Your Email*" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <textarea className="form-control" rows="5"
                                                            placeholder="Your Comment*"></textarea>
                                                    </div>
                                                    <button type="submit" className="theme-btn"><i
                                                            className="far fa-paper-plane"></i> Post Comment</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>  )}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
<SideBar />
                    </div>
                </div>
            </div>
        </div>








    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
