import React from 'react';

export default function SideBar() {

    return (
        <aside className="sidebar">

            <div className="widget category">
                <h5 className="widget-title">Welcome</h5>
                <div className="category-list">
                        <p>Welcome to our site, which aims to give you the best guides and resources when it comes to working out and doing exercises.</p>

                    <ul>
                        <li><a href="/3-day-a-week-workout-routine-monday-wednesday-friday/">3  times a week workout routine</a></li>
                        <li><a href="/30-minute-bodyweight-home-workout-plan/">30 Minute bodyweight workout at home</a></li>
                        <li><a href="/5-day-a-week-workout-routine/">5 Day a week workout  routine for muscle growth (5 day split workout)</a></li>
                        <li><a href="/best-30-minute-workout-routine/">Best full body 30 minute workout routine</a></li>
                        <li><a href="/best-7-day-workout-routine/">Complete 7 day workout routine</a></li>
                        <li><a href="/best-abs-workout-routine-and-exercises/">Best abs workout routine and exercises</a></li>
                        <li><a href="/best-back-workout-routine/">Best back workout routine</a></li>
                        <li><a href="/best-warm-up-exercises-workout-plan/">Best warm up exercises to do in your workout plan</a></li>
                        <li><a href="/chest-workout-routine/">How to start an exercise routine</a></li>
                        <li><a href="/get-in-shape-without-a-gym/">Get in shape without a gym</a></li>
                        <li><a href="/how-to-build-your-workout-routine/">How to build your workout routine</a></li>
                        <li><a href="/how-to-start-exercising-routine/">How to start an exercise routine</a></li>
                        <li><a href="/leg-day-workout-routine/">Best leg workout routine</a></li>
                        <li><a href="/resistance-band-training-workout-routines/">Resistance band training exercises and workout routine</a></li>
                        <li><a href="/strength-training-workout-routine/">Strength training workout routine for beginners</a></li>
                        <li><a href="/top-shoulder-gym-workout-routines-and-exercises/">Best shoulder gym workout exercise routines</a></li>
                        <li><a href="/upper-body-workout-routine-for-beginners/">Upper body workout routine for beginners</a></li>
                        <li><a href="/workout-routine-for-gym-beginners/">Workout routine for gym beginners</a></li>
                        </ul>
                </div>

            </div>


        </aside>
    )
    return (
        <aside className="sidebar">

        {/*<div className="widget search">*/}
        {/*    <h5 className="widget-title">Search</h5>*/}
        {/*    <form className="search-form">*/}
        {/*        <input type="text" className="form-control" placeholder="Search Here..." />*/}
        {/*        <button type="submit"><i className="far fa-search"></i></button>*/}
        {/*    </form>*/}
        {/*</div>*/}

        <div className="widget category">
            <h5 className="widget-title">Category</h5>
            <div className="category-list">
                <a href="#"><i className="far fa-angle-double-right"></i>Body
                    Building<span>(10)</span></a>
                <a href="#"><i className="far fa-angle-double-right"></i>GYM &
                    Fitness<span>(15)</span></a>
                <a href="#"><i className="far fa-angle-double-right"></i>Food &
                    Medicine<span>(20)</span></a>
                <a href="#"><i className="far fa-angle-double-right"></i>Cycling
                    GYM<span>(25)</span></a>
                <a href="#"><i className="far fa-angle-double-right"></i>Boxing &
                    Caradio<span>(30)</span></a>
            </div>
        </div>

        <div className="widget recent-post">
            <h5 className="widget-title">Recent Post</h5>
            <div className="recent-post-single">
                <div className="recent-post-img">
                    <img src="assets/img/blog/bs-1.jpg" alt="thumb" />
                </div>
                <div className="recent-post-bio">
                    <h6><a href="#">It is a long established fact that a reader</a></h6>
                    <span><i className="far fa-clock"></i>25 July, 2021</span>
                </div>
            </div>
            <div className="recent-post-single">
                <div className="recent-post-img">
                    <img src="assets/img/blog/bs-2.jpg" alt="thumb" />
                </div>
                <div className="recent-post-bio">
                    <h6><a href="#">It is a long established fact that a reader</a></h6>
                    <span><i className="far fa-clock"></i>25 July, 2021</span>
                </div>
            </div>
            <div className="recent-post-single">
                <div className="recent-post-img">
                    <img src="assets/img/blog/bs-3.jpg" alt="thumb" />
                </div>
                <div className="recent-post-bio">
                    <h6><a href="#">It is a long established fact that a reader</a></h6>
                    <span><i className="far fa-clock"></i>25 July, 2021</span>
                </div>
            </div>
        </div>

        <div className="widget social-share">
            <h5 className="widget-title">Follow Us</h5>
            <div className="social-share-link">
                <a href="#"><i className="fab fa-facebook-f"></i></a>
                <a href="#"><i className="fab fa-twitter"></i></a>
                <a href="#"><i className="fab fa-dribbble"></i></a>
                <a href="#"><i className="fab fa-whatsapp"></i></a>
                <a href="#"><i className="fab fa-youtube"></i></a>
            </div>
        </div>

        <div className="widget sidebar-tag">
            <h5 className="widget-title">Popular Tags</h5>
            <div className="tag-list">
                <a href="#">Gym</a>
                <a href="#">Fitness</a>
                <a href="#">Body</a>
                <a href="#">Food</a>
                <a href="#">Medicine</a>
                <a href="#">Boxing</a>
            </div>
        </div>
    </aside>
    )
}
